
import { reactive, computed } from "vue";

function safeJsonParse(val: string | null) {
    let data;
    try {
        data = JSON.parse(val!);
    } catch {}
    return data;
}

class ReactiveStorageItem {
    constructor(key: string, def?: any) {
        let object = safeJsonParse(localStorage.getItem(key));
        if (object?.value === undefined) {
            object = { value: def };
        }
        const item = reactive(object);
        return computed({
            get() {
                return item.value;
            },
            set(val: any) {
                item.value = val;
                localStorage.setItem(key, JSON.stringify(object));
            }
        });
    }
}

export const objectID = new ReactiveStorageItem("walldevice:objectID");
export const objectName = new ReactiveStorageItem("walldevice:objectName");
